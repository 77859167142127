import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "area", "wrapper", "video", "canvas", "itemCode" ]

  duplicate_check() {
    const ele = document.getElementsByClassName('stock-item-ids');
    for(let i = 0; i < ele.length; i++) {
      // console.log(ele[i].value);
      // console.log(this.itemCodeTarget.value);
      // console.log(i + '   ' + (ele[i] != this.itemCodeTarget));
      if(this.itemCodeTarget.value.length > 0 && ele[i] != this.itemCodeTarget) {
        if(ele[i].value === this.itemCodeTarget.value){
          alert('入力された管理番号が重複しています');
          this.itemCodeTarget.focus();
          return false;
        }
      }
    }
  }

  stop() {
    const area = this.areaTarget;
    const video = this.videoTarget;
    const canvas = this.canvasTarget;

    if(video.srcObject){
      video.pause();
      video.srcObject.getTracks().forEach(track => track.stop());
      video.srcObject = null;
    }
    canvas.style.display = 'none';
    area.style.display = 'none';
  }

  scan() {
    const area = this.areaTarget;
    const wrapper = this.wrapperTarget;
    const video = this.videoTarget;
    const canvas = this.canvasTarget;
    const item_code = this.itemCodeTarget;
    const ctx = canvas.getContext("2d");

    area.style.display = 'block';
    video.style.display = 'block';

    // カメラ設定
    const constraints = {
      audio: false,
      video: {
        width: 300,
        height: 200,
        facingMode: "environment"
      }
    };

    // カメラを<video>と同期
    navigator.mediaDevices.getUserMedia(constraints)
      .then( (stream) => {
        video.srcObject = stream;
        video.onloadedmetadata = (e) => {
          video.play();

          checkPicture();
        };
      })
      .catch( (err) => {
        console.log(err.name + ": " + err.message);
        const message = "<div class='error-message'>利用できるカメラがありません</div>";
        wrapper.insertAdjacentHTML('afterbegin', message);
      });

    // QRコードの読み取り
    function checkPicture(){
      // カメラの映像をCanvasに複写
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // QRコードの読み取り
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, canvas.width, canvas.height);

      // 存在する場合
      if( code ){
        // 結果を表示
        setQRResult("#result", code.data);  // 文字列
        drawLine(ctx, code.location);       // 見つかった箇所に線を引く

        // video と canvas を入れ替え
        canvas.style.display = 'block';
        video.style.display = 'none';
        video.pause();

        setTimeout( () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          video.srcObject = null;
          canvas.style.display = 'none';
          area.style.display = 'none';
          item_code.dispatchEvent(new Event('input'));
          item_code.dispatchEvent(new Event('change'));
        }, 1000);
      }

      // 存在しない場合
      else{
        // 0.3秒後にもう一度チェックする
        setTimeout( () => {
          checkPicture();
        }, 300);
      }
    }

    // 発見されたQRコードに線を引く
    function drawLine(ctx, pos, options={color:"red", size:5}){
      // 線のスタイル設定
      ctx.strokeStyle = options.color;
      ctx.lineWidth   = options.size;

      // 線を描く
      ctx.beginPath();
      ctx.moveTo(pos.topLeftCorner.x, pos.topLeftCorner.y);         // 左上からスタート
      ctx.lineTo(pos.topRightCorner.x, pos.topRightCorner.y);       // 右上
      ctx.lineTo(pos.bottomRightCorner.x, pos.bottomRightCorner.y); // 右下
      ctx.lineTo(pos.bottomLeftCorner.x, pos.bottomLeftCorner.y);   // 左下
      ctx.lineTo(pos.topLeftCorner.x, pos.topLeftCorner.y);         // 左上に戻る
      ctx.stroke();
    }

    // QRコードの読み取り結果を表示する
    function setQRResult(id, data){
      // $(id).innerHTML = escapeHTML(data);
      // $('input#qrcode').val(escapeHTML(data));
      item_code.value = escapeHTML(data);
    }

    // HTML表示用に文字列をエスケープする
    function escapeHTML(str){
      let result = "";
      result = str.replace("&", "&amp;");
      result = str.replace("'", "&#x27;");
      result = str.replace("`", "&#x60;");
      result = str.replace('"', "&quot;");
      result = str.replace("<", "&lt;");
      result = str.replace(">", "&gt;");
      result = str.replace(/\n/, "<br>");

      return(result);
    }
  }
}
