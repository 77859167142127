// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "wasteGenerators",
    "wasteSites",
    "wasteSiteAddress",
    "corporations",
    "customers",
    "wasteContracts"
  ]

  setCustomers() {
    $.ajax({
      url: '/corporations/customers',
      type: 'GET',
      dataType: 'json',
      data: {
        corporation_id: this.corporationsTarget.value
      },
      context: this,
    }).done(function(data){
      this.customersTarget.options.length = 0;
      console.log(data);
      for (let key in data) {
        let option = document.createElement("option");
        console.log(data[key].name);
        if(data[key].name === null || data[key].name === ''){
          option.text = '(顧客本社)';

        }else{
          option.text = data[key].name;
        }
        option.value = data[key].id;
        this.customersTarget.appendChild(option);
      }
      this.setWasteContracts();
    });
  };

  setWasteContracts() {
    $.ajax({
      url: '/corporations/waste_contracts',
      type: 'GET',
      dataType: 'json',
      data: {
        corporation_id: this.corporationsTarget.value
      },
      context: this,
    }).done(function(data){
      this.wasteContractsTarget.options.length = 0;
      for (let key in data) {
        let option = document.createElement("option");
        option.text = data[key].title;
        option.value = data[key].id;
        this.wasteContractsTarget.appendChild(option);
      }
    });
  };

  setWasteSites() {
    // console.log(this.wasteGeneratorsTarget);
    // console.log(this.wasteSitesTarget);
    $.ajax({
      url: '/sites/lists',
      type: 'GET',
      dataType: 'json',
      data: {
        waste_generator_id: this.wasteGeneratorsTarget.value
      },
      context: this,
    }).done(function(data){
      this.wasteSitesTarget.options.length = 0;
      // let option = document.createElement("option");
      // this.wasteSitesTarget.appendChild(option);
      for (let key in data) {
        let option = document.createElement("option");
        option.text = data[key].name;
        option.value = data[key].id;
        this.wasteSitesTarget.appendChild(option);
      }
      this.setWasteSiteAddress();
    });
  };

  setWasteSiteAddress() {
    if(this.wasteSitesTarget.value == ''){
      this.wasteSiteAddressTarget.value = '';
    } else {
      $.ajax({
        url: '/sites/info',
        type: 'GET',
        dataType: 'json',
        data: {
          id: this.wasteSitesTarget.value
        },
        context: this,
      }).done(function(data){
        // console.log(data['address_1']);
        this.wasteSiteAddressTarget.value = data['postal_code'] + ' ' + data['address_1'] + data['address_2'];
        // this.telTarget.value = data['tel_1'];
      }).fail(function(){
        window.location.href = '/';
      });
    }

  }
}

