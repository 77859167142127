import { Controller } from "stimulus";

// jquery, jquery.autoKana はsprockets側でロード

export default class extends Controller {

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    // let d = new Date();

    // $.datepicker.setDefaults({
    //   dateFormat: "yy-mm-dd",
    //   showButtonPanel: true,
    //   beforeShow: showAdditionalButton,
    //   onChangeMonthYear: showAdditionalButton
    // });

    // $('.datepicker').each(function() {
    //   let mindate = $(this).data('mindate') === void 0 ? new Date(2000, 0, 1) : new Date($(this).data('mindate'));
    //   let maxdate = $(this).data('maxdate') === void 0 ? new Date(d.getFullYear() + 1, d.getMonth(), d.getDate() - 1) : new Date($(this).data('maxdate'));
    //   let changeyear = $(this).data('changeyear') === void 0 ? false : true;
    //   return $(this).datepicker({
    //     minDate: mindate,
    //     maxDate: maxdate,
    //     changeYear: changeyear
    //   });
    // });
  }
}

// function showAdditionalButton(input) {
//   setTimeout((function() {
//     let buttonPanel = $(input).datepicker('widget').find('.ui-datepicker-buttonpane');
//     let btn = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all" type="button">クリア</button>');
//     btn.unbind('click').bind('click', function() {
//       $.datepicker._clearDate(input);
//     });
//     btn.appendTo(buttonPanel);
//   }), 1);
// };

