import { Controller } from "stimulus";

// jquery, dropzone はsprockets側でロード


export default class extends Controller {

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    let obj = this.data.get("object");
    new Dropzone('#file_upload', {
      paramName: 'attachment[file]',
      dictDefaultMessage: 'ここにファイルをドラッグ＆ドロップしてください<br/>' +
                          '<p>または、クリックしてファイルを選択してください</p>' +
                          '<p>上限 : 150MB<br/>( ファイルは複数選択できます )</p>',
      init: function() {
        this.on('success', function(file, json) {
          let description, destroy_box, destroy_div, destroy_label, file_type, fname, icon, id, img, li;
          id = $('<input>').attr({
            'type': 'hidden',
            'value': json['id'],
            'name': obj + "[attachments_attributes][" + json['id'] + "][id]",
            'id': obj + "_attachments_attributes_" + json['id'] + "_id"
          });
          file_type = file['name'].match(/(.*)(?:\.([^.]+$))/)[2].toUpperCase();
          switch (file_type) {
            case 'XLS':
            case 'XLSX':
            case 'DOC':
            case 'DOCX':
            case 'ZIP':
            case 'PDF':
            case 'TXT':
            case 'XDW':
            case 'JWW':
              switch (file_type) {
                case 'XLS':
                case 'XLSX':
                  icon = $('<img>').attr({'src': "/assets/filetypes/xls-c51c5270cdb3c9580bf5378ad4ea565055fe58bea166bbbb0bd4061c58a7bf9c.png"}).addClass('resize');
                  break;
                case 'DOC':
                case 'DOCX':
                  icon = $('<img>').attr({'src': "/assets/filetypes/doc-fc5f35d30b2db9cc68c8de7231dcb82d29dd3e0df712370a5f2b64a62a18982d.png"}).addClass('resize');
                  break;
                case 'TXT':
                  icon = $('<img>').attr({'src': "/assets/filetypes/txt-de9b80cccc66a00e92a7d56404c9a55d247663f393985091d984db3314a2991c.png"}).addClass('resize');
                  break;
                case 'ZIP':
                  icon = $('<img>').attr({'src': "/assets/filetypes/zip-610c8d118524be415c9f01b624d43da35887f269a0605c2920615c163bcb7090.png"}).addClass('resize');
                  break;
                case 'XDW':
                  icon = $('<img>').attr({'src': "/assets/filetypes/dw-dc28e5b05a73e0ba7fef953c1e31d38ee22becf82694656c6755750f922ea014.png"}).addClass('resize');
                  break;
                case 'JWW':
                  icon = $('<img>').attr({'src': "/assets/filetypes/jww-41aaf66e9529a6688acd57244bbbec81ffb954cd95f9d89671ac59327fcbc81b.png"}).addClass('resize');
                  break;
                default:
                  icon = $('<img>').attr({'src': json['file']['thumb']['url']});
              }
              fname = $('<span>').html("" + file['name']);
              img = $('<div class=file_type>').append(icon).append(fname);
              break;
            default:
              img = $('<img>').attr({'src': json['file']['thumb']['url']});
          }
          description = $('<input>').attr({
            'style': 'margin-top: 4px;',
            'type': 'text',
            'name': obj + "[attachments_attributes][" + json['id'] + "][description]",
            'id': obj + "_attachments_attributes_" + json['id'] + "_description"
          });
          destroy_box = $('<input>').attr({
            'type': 'checkbox',
            'value': 'true',
            'name': obj + "[attachments_attributes][" + json['id'] + "][_destroy]",
            'id': obj + "_attachments_attributes_" + json['id'] + "__destroy"
          });
          destroy_label = $('<label>').attr({
            'for': obj + "_attachments_attributes_" + json['id'] + "__destroy"
          }).html("削除");
          destroy_div = $('<div style="text-align: center;">').append(destroy_box).append(destroy_label);
          li = $('<li class="cell ui-sortable-handle">').append(id).append(img).append(description).append(destroy_div);
          $('ul#uploaded-files').append(li);
        });
      }
    });
  }
}
