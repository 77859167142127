// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "ctrUser",
    "address",
    "tel"
  ]

  setCtrUserInfo() {
    // console.log(this.ctrUserTarget.value);
    $.ajax({
      url: '/ctr_users/info',
      type: 'GET',
      dataType: 'json',
      data: {
        id: this.ctrUserTarget.value
      },
      context: this,
    }).done(function(data){
      // console.log(data['address_1']);
      this.addressTarget.value = data['address_1'];
      this.telTarget.value = data['tel_1'];
    }).fail(function(){
      window.location.href = '/';
    });
  };
}

