// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "dateOutput",
    "personOutput",
    "locInput",
    "locOutput"
  ]

  setNow() {
    this.dateOutputTarget.value = (formatDate(new Date(), 'yyyy-MM-ddTHH:mm'));
  };

  setPerson() {
    this.personOutputTarget.value = this.data.get("person");
  };

  setLocs() {
    const all_locs = JSON.parse(this.data.get("locs"));
    const selected_locs = all_locs[this.locInputTarget.value];
    this.locOutputTarget.options.length = 0;
    let option = document.createElement("option");
    this.locOutputTarget.appendChild(option);
    for (let key in selected_locs) {
      // console.log(key, selected_locs[key]);
      let option = document.createElement("option");
      option.text = selected_locs[key];
      option.value = key;
      this.locOutputTarget.appendChild(option);
    }

  };
}

function formatDate(date, format) {
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
  return format;
};
 
