import { Controller } from "stimulus";

export default class extends Controller {

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    if(navigator.userAgent.match(/(iPhone|iPad|Android)/)){
    } else {
      $('#search_keyword').focus();
    }
  }

  // --------------------------------------------------------
  // SEARCH
  // --------------------------------------------------------
  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let form = document.querySelector('form#search');
      Rails.fire(form, 'submit');
      // form.requestSubmit();
    }, 200);
  }

  // --------------------------------------------------------
  // CLEAR
  // --------------------------------------------------------
  clear() {
    $('table.status input[type=checkbox]').prop("checked", false);
    this.search();
  }
}
